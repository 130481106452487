@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scale-in {
    0% {
        opacity: 0;
      transform: scale(.5);
    }
    100% {
        opacity: 1;
      transform:  scale(1);;
    }
  }
@-webkit-keyframes scale-in {
    0% {
        opacity: 0;
      transform: scale(.5);
    }
    100% {
        opacity: 1;
      transform:  scale(1);;
    }
}
