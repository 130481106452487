.related {
  border-top: 1px solid $hr-color;
  padding: 1.5em 0 3em;
  position: relative;
  
  h2 {
    margin: 0 0 1em;
  }
  .viewall {
    position: absolute;
    top: 1.5em;
    right: 0;

    a {
      &, &:link, &:visited {
        display: inline-block;
        font-size: .95em;
        text-decoration: none;
        border-bottom: 2px solid $link-color;
      }
      &:hover, &:active, &:focus {
        border-color: $hover-color;
      }
    }
  }
}