.freeform-column {
  text-align: left;

  label {
    display: block;
    padding: .5em 0 .25em;

    font-size: .875em;
    font-weight: 400 !important;
    line-height: 1.4;
    text-transform: uppercase;
  }
  input[type="text"], input[type="email"], input[type="url"], input[type="password"], textarea, select {
    border: 1px solid $hr-color;

    width: 100%;
    padding: .75em .75em;
    margin: 0 0 .25em;
    transition: all .2s ease-in-out;

    &:active, &:focus {
      border: 1px solid $color-black;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: $color-white url($image-path + 'icon-dropdown.svg') no-repeat;
    background-position: calc(100% - 12px) 50%
  }
  .input-group-one-line {
    label {
      flex: 0 1 25%;
      text-transform: none;
    }
  }
}

.ff-form-success {
	background: #eef9f6;
	border: 1px solid green;
	padding: 16px 20px 18px;
	text-align: center;
	margin-bottom: 24px;

  p {
    margin: 0;
    padding: 0;
    color: green;
  }
}

.ff-form-errors {
  padding: 16px 20px 18px !important;
  border: 1px solid $color-red !important;
  background: lighten($color-red, 55%) !important;
  border-radius: 0 !important;
  color: $color-red !important;
  text-align: center;

  p {
    margin: 0;
    padding: 0;
    color: $color-red;
  }
}

.freeform-required:after {
    content: "*";
    margin-left: 4px !important;
    color: $color-red !important;
}

.ff-errors>li {
    color: $color-red !important;
}
