$input-radius: 5px;
$input-border: 1px solid $hr-color;
$input-background: $color-white;
$highlight-color: $color-red;
$secondary-color: $color-red-light;

.donate {
  padding: 4em 0;

  // background-image: url('https://dw9ju0yhgkqbo.cloudfront.net/images/banners/shutterstock_105882605.png');
  background-color: $color-offwhite;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .container {
    max-width: $limited-width;
    border-radius: 4px;
    background: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25),
                0px 2px 30px rgba($color-red, 0.15),
                0px 6px 70px rgba(0, 0, 0, 0.25);

    h1 {
      text-align: center;
      border-bottom: 1px solid $hr-color;
      margin: 0 0 .75em;
      padding: .375em 0;
      font-size: 36px;
      display: block;

      @media (max-width: $small-screen) {
        font-size: 30px;
      }

      &::after {
        display: none;
      }
    }
  }
  .fund-link {
    max-width: $limited-width;
    background: none;
    box-shadow: none;
    text-align: right;
    margin: -15px auto 0 auto;

    p {
      font-size: 14px;
      padding: 0 0 10px;
      color: white;
      text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25),
                  0px 2px 30px rgba($color-red, 0.15),
                  0px 6px 70px rgba(0, 0, 0, 0.25);
      a {
        color: white;
        text-decoration-color: rgba(white, .75);

        &:hover {
          opacity: .85;
          text-decoration-color: rgba(white, 1);
        }
      }
    }
  }
}

.donation-form {
  padding: 1em 2em 2em;
  .df-alert {
    border: 1px solid $hr-color;
    border-radius: $input-radius;
    background: #dfdfdf;
    padding: 1em;
    margin-bottom: 2em;
  }
  p.df-steps {
    margin-bottom: 0;
    padding-bottom: 0;

    color: $secondary-color;
    font-size: .75em;
    font-family: $accent-font;
    text-transform: uppercase;
  }
  h2 {
    margin-top: 0;
  }
  h3 {
    margin: .75em 0 .5em;
  }
  p:last-child {
    padding: 0 0 1.25em;
  }
  hr {
    border-top: 4px solid $secondary-color;
  }

  hr.thin {
    border-top: 1px solid $hr-color;
  }

  .row, .inline-field {
    display: flex;

    @media screen and (max-width: $small-screen){
      flex-wrap: wrap;
    }
  }
  .row > .field {
    flex: 0 0 100%;

    @media screen and (min-width: $small-screen){
      flex: 1;
      input[type="text"], input[type="number"], input[type="email"], textarea, select {
        border-radius: 0;
      }
      &:first-child {
        input[type="text"], input[type="number"], input[type="email"], textarea, select {
          border-top-left-radius: $input-radius;
          border-bottom-left-radius: $input-radius;
        }
      }
      &:last-child {
        input[type="text"], input[type="number"], input[type="email"], textarea, select {
          border-top-right-radius: $input-radius;
          border-bottom-right-radius: $input-radius;
        }
      }
    }

  }

  .field {
    fieldset {
      margin: 0;
      padding: 0;
      width: 100%;
    }
    label, legend {
      display: block;
      padding: .5em 0;

      font-size: .75em;
      font-family: $accent-font;
      line-height: 1.5;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .df-instruction-label {
      label, legend {
        display: inline-block;
      }
    }
    .df-instructions {
      position: relative;
      display: inline-block;

      .df-tooltip {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        transform: translate(-46%, -110%);
        width: 200px;

        background: $highlight-color;
        border-radius: 5px;
        padding: .75em;

        text-align: center;
        color: white;
        font-size: .75rem;
        line-height: 1.2;
        text-transform: none;

        &:after {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: transparent;
          border-top-color: $secondary-color;
          border-width: 10px;
          margin-left: -10px;
          margin-top: -1px;
        }
      }
      img {
        width: 17px;
        height: auto;
        transform: translateY(-1px);

        &:hover, &:active, &:focus {
          cursor: help;

          ~ .df-tooltip {
            visibility: visible;
          }
        }
      }
    }
    input[type="text"], input[type="number"], input[type="email"], textarea, select {
      -webkit-appearance: none;

      background: $input-background;
      border: $input-border;
      border-radius: $input-radius;

      width: 100%;
      padding: .75em 1em;
      margin: 0 0 .5em;

      font-size: 16px;

      &::placeholder {
        opacity: .5;
      }
    }

    textarea {
      height: auto;
      width: 100%;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: url($image-path + 'icon-down.svg') no-repeat right white;
      background-position: calc(100% - 12px) 16px;
      background-size: 16px;
      //box-shadow: 0 1px 6px 0 rgba($link-color, .1);
    }

    .inline-field label {
      font-family: $main-font;
      text-transform: none;
      letter-spacing: 0;
    }
    .form-check {
      display: flex;
      justify-content: flex-start;

      margin: 0 1em .5em 0;

      label, legend {
        font-family: $main-font;
        line-height: 1.1;
        letter-spacing: 0px;
        text-transform: none;

        display: flex;
        justify-content: flex-start;

        &:before {
          display: inline-block;
          content: '';

          height: 18px;
          width: 18px;
          border: 1px solid $hr-color;
          border-radius: 4px;
          margin-right: 5px;
          margin-top: -5px;
        }
      }

      input[type="radio"], input[type="checkbox"] {
        //margin-top: 5px;
        //display: none;
        visibility: hidden;
        width: 1px;

        &:active, &:focus {
          ~ label {
            &:before {
              border-color: $highlight-color;
            }
          }
        }

        &:checked {
          ~ label {
            &:before {
              background: $highlight-color url($image-path + "icon-check-white.svg") no-repeat;
              background-size: 12px 10px;
              background-position: center center;
            }
          }
        }
      }
    }

    .df-amount {
      position: relative;
      margin: 0 0 .5em;

      &:before {
        display: block;
        content: '$';

        position: absolute;
        top: 50%;
        left: 1em;
        transform: translateY(-50%);

        color: $highlight-color;
        font-size: 1em;
      }

      &:after {
        display: block;
        content: 'USD';

        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);

        color: $highlight-color;
        font-size: .9em;
        //text-transform: uppercase;
      }
      &.monthly {
        &:after {
          content: 'USD/Month';
        }
      }
      &.quarterly {
        &:after {
          content: 'USD/Quarterly'
        }
      }

      input {
        padding: .25em 5em .25em 1.5em;
        margin: 0;

        color: $highlight-color;
        font-size: 1.5em;
        font-weight: bold;
        line-height: 1;
      }
    }


    &.form-toggle {
      display: flex;
      flex-wrap: wrap;

      margin: 0 0 1em;

      div {
        flex: 1;
      }
      .form-check {
        margin: 0;
        padding: 0;

        label {
          position: relative;
          display: block;
          width: 100%;
          cursor: pointer;

          padding: 8px 10px;
          border: 2px solid $secondary-color;

          text-align: center;
          color: $secondary-color;
          font-size: 1em;
          transition: all .5s ease-in-out;

          &:before { display: none; }
          &:after {
            transition: all .5s ease-in-out;
          }
        }
        input[type="radio"], input[type="checkbox"] {
          display: none;

          &:checked {
            ~ label {
              background: $secondary-color;
              color: $color-white;

              &:after {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);

                display: block;
                content: url($image-path + "icon-check-white.svg");
              }
            }
          }
        }

        &:first-child label {
          border-radius: $input-radius 0 0 $input-radius;
        }
        &:last-child label {
          border-radius: 0 $input-radius $input-radius 0;
        }
      }
    }
  }

  .df-review {
    h3 {
      border-top: 1px solid $color-grey-light;
      padding-top: 12px;
      margin: 10px 0 4px 0;
      font-size: .875em;
    }
    p {
      padding: 0;
      margin: 0;
      font-size: .875em;

      strong {
        font-weight: 700;
      }
    }
    p.donationAmount {
      color: $highlight-color;
      font-weight: bold;
      font-family: $accent-font;
      font-size: 1.4375em;
    }
  }

  .df-buttons {
    display: flex;
    justify-content: space-between;

    padding: 2em 0;


    > button {
      flex: 1;
    }

    &.multi-btn {
      > button {
        flex: 0 1 50%;
      }
    }
  }

  .or-paypal {
    text-align: center;
    width: 100%;

    span {
      display: block;
      color: $color-grey-dark;
      font-size: .75em;
      font-family: $accent-font;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      text-align: center;
      margin: 0 0 2.5em;

      &:before, &:after {
        position: absolute;
        top: 52%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '\a0';
        background-color: #ddd;
      }
    }
    span:before {
      margin-left: -50%;
      text-align: right;
    }
  }

  #paypal-button-container {
    text-align: center;
  }
  .primaryButton, .secondaryButton {
    border-radius: $input-radius;
    text-align: center;
    cursor: pointer;
  }

  .multi-btn {
    .primaryButton, .secondaryButton, .AcceptUI {
      &:first-child {
        border-radius: $input-radius 0 0 $input-radius;
      }
      &:last-child {
        border-radius: 0 $input-radius $input-radius 0;
      }
    }
  }
  .primaryButton, .secondaryButton, .AcceptUI {
    display: inline-block;
    outline: none;

    padding: 1em 1.5em;
    margin: 0px;

    background: $highlight-color;
    border: 2px solid $highlight-color;

    transition: all .2s;
    text-align: center;

    color: $color-white !important;
    font-size: 1rem;
    font-family: $accent-font;
    font-weight: 500;
    font-style: normal;
    line-height: 1.1;
    text-decoration: none;
    text-shadow: none;

    @media screen and (max-width: $small-screen) {
      padding: .75em 1em;
      font-size: .9rem;
    }

    &:hover, &:active, &:focus {
      background: $secondary-color;
      border-color: $secondary-color;;
      cursor: pointer;
    }

    &[disabled] {
      cursor: initial;
      background: $color-grey-light;
      border-color: $color-grey-light;
      color: $color-grey !important;
      cursor: not-allowed !important;
    }
  }
  .secondaryButton {
    background: $color-white;
    border-color: $color-grey-light;
    color: $highlight-color !important;
    cursor: w-resize !important;

    &:hover, &:active, &:focus {
      background: $secondary-color;
      color: $color-white !important;
    }
  }

  p.not-sure {
    font-size: 13px;
    text-align: center;
    background: $color-offwhite;
    padding: 5px;
    border-radius: 4px;
  }
}

#hnypt { display: none; }

  // Loading screen
  #donate-form {
    position: relative;
  }
  .loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;

    background: rgba($color-white, .95);
    text-align: center;
    padding: 50px;
  }
  .loading-text {
    display: block;
    text-transform: uppercase;
    color: $color-black;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $highlight-color;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $highlight-color transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .messages.success {
    padding: 1em 2em 1em;
    margin:0 2em;
    background: rgba($color-red-light, .3);
    border: solid 1px $color-red;
  }


  .success {
    background: #E6F9EA;
    border: 1px solid #006811;
    padding: 8px 20px 20px 20px;
    margin: 0 0 40px;
    color: #006811;

    h3, p {
      color: #006811;
    }
  }

  .authorize-errors {
    background: #FFF0F0;
    border: 1px solid #CC0000;
    padding: 8px 20px 20px 20px;
    margin: 0 0 40px;
    color: #CC0000;

    h3, p {
      color: #CC0000;
    }
  }

  p.notice {
    background: #FFF0F0;
    border: 1px solid #CC0000;
    padding: 8px 20px 8px 20px;
    margin: 0 0 40px;
    color: #CC0000;
  }

  .donation-form-success {
    p {
      margin-bottom: 1em;
    }
  }
