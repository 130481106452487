.largetext {
  color: $color-grey-mid;
  font-size: 1.375em;
  line-height: 1.5;
}

.attribution {
  display: block;

  color: $color-white;
  font-size: .8rem;
  font-family: $accent-font;
  font-weight: normal;
  text-transform: uppercase;

  &.grey {
    color: $color-grey-dark;
  }
}

.date {
  font-size: .8em;
}
.small-text {
  color: $color-grey-mid;
  font-size: 1em;
}
p.small-text {
  margin: 0 0 .25em !important;
  padding: 0 !important;
}

.category {
  color: $color-red;
  font-size: 1em;
}
p.category {
  margin: 0 0 .25em !important;
  padding: 0 !important;
}

.dropcap {
  p:first-of-type::first-letter {
    float: left;
    padding: 5px 10px 5px 0;

    color: $color-grey-dark;
    font-size: 2.5em;
    font-family: $accent-font;
    line-height: 1;
  }
}
