.column-content {
  &.lightGrey { background-color: $color-offwhite; }

  .container {
    padding: 3em 0;
  }
  .column-header {
    &:after {
      display: block;
      content: '';

      height: 4px;
      width: 60px;
      margin: 10px 0 0;
      background: $color-red;
    }
  }
  .columns {
		padding: 1em 0;
  }

  &.two {    
    .columns {
      @media screen and (min-width: $small-screen) {
        column-count: 2;
        column-gap: 4em;
      }
    }
  }
  &.three {    
    .columns {
      @media screen and (min-width: $small-screen) {
        column-count: 3;
        column-gap: 3em;
      }
    }
  }
  &.four {    
    .columns {
      @media screen and (min-width: $xsmall-screen) {
        column-count: 2;
        column-gap: 2em;
      }
  
      @media screen and (min-width: $small-screen) {
        column-count: 4;
        column-gap: 2em;
      }
    }
  }
}