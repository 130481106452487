.share {
  padding: 1em 0;
  margin-bottom: 2em;
  text-align: center;

  ul {
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    li {
      margin: 0 5px;
      padding: 0;
      list-style-type: none;
      list-style-image: none;
    }
  }
  a {
    &, &:link, &:visited {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 42px;
      width: 42px;

      background: transparent;
      border: 1px solid $hr-color;
      border-radius: 50%;
      line-height: 1;
      transition: background .2s ease-in-out;
    }
    &:hover, &:active, &:focus {
      background: $color-offwhite;
    }
  }
  img, svg {
    max-height: 20px;
    width: 20px;

    path { fill: $link-color; }
  }
}