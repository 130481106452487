.accordion-block {
    margin: 80px 0;
}

.accordion {
  width: 100%;
  margin-bottom: 40px;

  p,
  ul {
    &:last-child {
      margin: 0;
      padding: 0;
    }
  }

  &__button {
    position: relative;
    display: block;
    margin: 0px 0 0;
    border: 1px solid $hr-color;
    padding: 18px 16px;
    text-align: left;
    color: $color-black;
    background: $color-offwhite;
    font-size: 16px;
    width: 100%;
    transition: 0.2s;
    cursor: pointer;

    &::after {
      content: '';
      background: url($image-path + 'icon-down.svg') no-repeat;
      background-size: 20px 20px;
      height: 20px;
      width: 20px;
      position: absolute;
      right: 20px;
      display: inline-block;
      transition: transform .2s;
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:hover {
      background: $color-offwhite;
      outline: none;
      border: 1px solid $color-black;
      cursor: pointer;
      box-shadow: 0 1px 12px 0 rgba($color-black, 0.1);
    }

    &:focus,
    &:active {
      background: $color-offwhite;
      outline: none;
      border: 1px solid $color-black;
      cursor: pointer;
      box-shadow: 0 1px 12px 0 rgba($color-black, 0.1);

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__button.expanded {
    background: $link-color;
    color: $color-white !important;
  }

  &__section {
    border-left: 1px solid $hr-color;
    border-right: 1px solid $hr-color;
    background: $color-white;
    max-height: 0vh;
    overflow: hidden;
    padding: 0;
    opacity: 1;
  }

  &__section.open {
    max-height: 100vh;
    overflow: auto;
    padding: 18px;
    visibility: visible;
    border-bottom: 1px solid $hr-color;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    animation: appear 0.25s linear;
  }
  @keyframes appear {
    0% {
      opacity: 0;
      visibility: hidden;
      transform: translateY(6px);
    }
    100% {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}
