[data-tipped] {
	position: relative;
	display: inline-block;
	z-index: 9999;

  text-transform: none;

  // tooptip styles
	&:before {
		position: absolute;
    z-index: 2;
		bottom: -28px;
		left: 50%;
		content: attr(data-tipped);
		padding: 4px 6px;
		color: white;
		font-size: 13px;
		line-height: 1.3;
		font-weight: 500;
		text-overflow: ellipsis;
		background: $color-red;
		box-shadow: 0 0 4px rgba(black,0.1),
                0 0 20px rgba($color-red,0.15);
		white-space: nowrap;
		opacity: 0;
		transform: perspective(1px) translate3d(-50%,-10px,0);
		pointer-events: none;
		transition: all 0.1s 0.3s;
	}

  // tooptip arrow
	&:after {
		position: absolute;
    z-index: 1;
		content: '';
		bottom: -12px;
		left: 50%;
		width: 8px;
		height: 8px;
		background: $color-red;
		opacity: 0;
		transform: translate3d(-50%,-10px,0) rotate(45deg);
		pointer-events: none;
		transition: all 0.1s 0.3s;
	}

	&:hover:before {
		opacity: 1;
		transform: perspective(1px) translate3d(-50%,0,0);
	}

	&:hover:after {
		opacity: 1;
		transform: translate3d(-50%,0,0) rotate(45deg);
	}
}
