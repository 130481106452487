/************* HTML Styles *************/
*,
*::before,
*::after { box-sizing: inherit; }

@viewport { width:device-width; }

html {
  overflow-x: auto;
  overflow-y: scroll;
}
body {
  box-sizing: border-box;
 // overflow: hidden;

  background: $bkgd-body;

  color: $font-color;
  @include fluid-type($min-font, $max-font);
  font-family: $main-font;
  line-height: $main-lineheight;
}

h1 {
  margin: 0 0 1.1rem;

  color: $color-grey-dark;
  font-size: 2.75em;
  font-family: $accent-font;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 1px;

  &:after {
    display: block;
    content: '';

    height: 4px;
    width: 60px;
    margin: 10px auto 0;
    background: $color-red;
  }
}

h2 {
  margin: .375em 0 .75em;

  color: $color-grey-dark;
  font-size: 1.7em;
  font-family: $accent-font;
  font-weight: bold;
  line-height: 1.25;

  &.section-header {
    text-align: center;
    margin: 2em 0 2em;
    font-size: 2em;

    &:after {
      display: block;
      content: '';

      height: 4px;
      width: 60px;
      margin: 10px auto 0;
      background: $color-red;
    }
  }
}

h3 {
  margin: .5em 0 .75em;

  color: $color-grey-dark;
  font-size: 1.3em;
  font-family: $accent-font;
  font-weight: 600;
  line-height: 1.25;
}

h4 {
  margin: 0 0 0.5rem;

  color: $font-color;
  font-size: 1em;
  font-family: $main-font;
  font-weight: bold;
  line-height: 1.1;
}

h5 {
  margin: 0 0 1.5rem;

  color: $font-color;
  font-size: 0.8rem;
  font-family: $main-font;
  font-weight: bold;
  line-height: 1.1;
}

h6 {
  margin: 0 0 1.25rem;

  color: $color-red;
  font-size: .9em;
  font-family: $accent-font;
  font-weight: 500;
  line-height: 1.1;
  text-transform: uppercase;
}

blockquote {
  border-left: 4px solid $color-red;
  padding: 0 0 0 1em;
  margin: 0 0 2em;

  color: $color-red;
  font-size: 1.35em;
  font-family: $accent-font;
  font-weight: 300;
  line-height: 1.25;

  strong {
    color: $color-grey-mid;
    font-size: 0.8rem;
    font-family: $accent-font;
    font-weight: normal;
    line-height: 1.4;
    text-transform: uppercase;
  }
}

table {
  border-collapse: collapse;
}
sup,
sub {
  font-size: 80%;
}
p {
  margin: 0;
  padding: 0 0 1.25em;
}
ul,
ol {
  margin: 0;
  padding: 0 0 1.5em;
}

ul ul,
ol ol {
  padding: 0;
}

ul li {
  margin: 0 0 0.75em 2em;
  padding: 0;
  list-style-image: url($image-path + "bullet.png");
}

ul li * {
  margin-top: 0;
}
ul li p {
  margin: 0;
  padding: 0;
}
ol li {
  margin: 0 0 0.5rem 2rem;
}
a,
a:link,
a:visited {
  color: $link-color;
  font-weight: 500;
  text-decoration: underline;
  outline: none;
  cursor: pointer;

  transition: color 0.2s ease-out,
            background 0.2s ease-in,
            opacity 0.2s ease-out,
            text-decoration 0.2s ease-out,
            border 0.2s ease-out,
            box-shadow 0.2s ease-out;
}
a:hover,
a:active,
a:focus {
  color: $hover-color;
}
a:active, a:focus {
  outline: rgb(94, 158, 215) auto 5px;
}

img {
  width: auto;
  max-width: 100%;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $hr-color;
  margin: 1em 0;
  padding: 0;
  clear: both;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input,
textarea,
select {
  font-family: $main-font;
  font-size: 1em;
}

figure {
  margin: 0;
}
figcaption {
  display: block;
}

::-moz-selection {
  background: $highlight-color;
  text-shadow: none;
}
::selection {
  background: $highlight-color;
  text-shadow: none;
}
