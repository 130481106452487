@mixin icoMoon-font {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin fluid-type(
	$min-font-size: 12px, $max-font-size: 21px,
	$lower-range: 420px, $upper-range: 900px ) {
  font-size: calc(
    #{$min-font-size} + #{(
        ($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size *
                  0 + 1))
      )} *
      (
         (100vw - #{$lower-range}) / #{(
            ($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range *
                      0 + 1))
          )}
      )
  );

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}
@mixin transform($transform) {
  -webkit-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

@mixin graphic-bkgd {
  background-image: url($image-path + 'diagonal-lines.svg');
  background-size: 28px 28px;
  background-repeat: repeat;
}