.card-list {
  padding: 1em 0 3em;
}
.card-grid {

  @media all and (min-width: $small-screen) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    gap: 25px;
    align-items: start;
  }
}

.card {
  &, &:link, &:visited {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin-bottom: 1em;

    background: $color-white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07),
                0px 4px 40px rgba(0, 0, 0, 0.06);

    color: $color-grey-dark;
    text-decoration: none;

    @media all and (min-width: $small-screen) {
      flex-direction: column;
    }
  }
  &:hover, &:active, &:focus {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1),
                0px 5px 50px rgba(0, 0, 0, 0.1);
  }

  figure {
    margin: 0;
    position: relative;
    max-height: 262px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: 262px;
      transition: transform 0.2s linear;
    }

    @media all and (max-width: $small-screen) {
      flex: 0 1 35%;
      height: auto;

      img {
        min-height: initial;
      }
    }


    .videoIcon {
      position: absolute;
      background: rgba($color-white, .5);
      border: 1px solid $color-red;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transition: transform 0.2s linear;

      display: flex;
      justify-content: center;
      align-items: center;

      img, svg {
        width: 30px;
        height: 30px;
        min-height: auto;
        object-fit: auto;

        path {
          fill: $color-red;
        }
      }
    }
  }
}
.card-content {
  padding: 2em 1.5em;

  @media all and (max-width: $small-screen) {
    flex: 1;
    margin-left: 2em;
  }

  span, strong {
    display: block;
  }
  .small-text {
    color: $color-red;
    font-size: .9em;
  }
  strong {
    margin: .5em 0;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.25;
  }
}
.card-btn {
  margin-top: auto;
  border-top: 1px solid $hr-color;
  padding: 1em 1.5em;
  text-align: center;

  @media all and (max-width: $small-screen) {
    flex: 0 0 100%;
  }
  p { padding: 0; }
  a {
    &, &:link, &:visited {
      text-decoration: none;
    }
    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }
}



.list-card {
  &, &:link, &:visited {
    display: flex;
    padding: 1em 1em;
    border-bottom: 1px solid $hr-color;

    color: $color-grey-dark;
    text-decoration: none;
  }
  &:hover, &:active, &:focus {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1),
                0px 5px 50px rgba(0, 0, 0, 0.1);
  }

  figure {
    align-self: center;
    margin: 0;
    position: relative;

    flex: 0 1 25%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      min-height: initial;
      transition: transform 0.2s linear;
    }

    .videoIcon {
      position: absolute;
      background: rgba($color-white, .5);
      border: 1px solid $color-red;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transition: transform 0.2s linear;

      display: flex;
      justify-content: center;
      align-items: center;

      img, svg {
        width: 30px;
        height: 30px;
        min-height: auto;
        object-fit: auto;

        path {
          fill: $color-red;
        }
      }
    }
  }

  .card-content {
    margin: 0;
    padding: 0em 1.5em;
    flex: 1;
  }

}