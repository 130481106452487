
.image-block {

  .container {
    padding: 4.5em 0;
  }

  &.divider {
    .container {
      border-bottom: 1px solid $hr-color;
    }
  }

  .block-image {
    margin-bottom: 2em;
  }
  @media all and (min-width: $small-screen) {
    .container {
      display: flex;
      justify-content: space-between;

      .block-image {
        flex-basis: 48%;
        max-width: 540px;
        margin-bottom: 0;
        margin-right: 2%;
      }
      .block-text {
        flex-basis: 48%;
        align-self: center;
      }
    }

    &.right {
      .container {
        flex-direction: row-reverse;
      }

      .block-image {
        margin-left: 2%;
      }
    }
  }
}


.embed-block {

  .container {
    padding: 4.5em 0;
  }

  &.divider {
    .container {
      border-bottom: 1px solid $hr-color;
    }
  }

  .block-embed {
    margin-bottom: 2em;
  }
  @media all and (min-width: $small-screen) {
    .container {
      display: flex;
      justify-content: space-between;

      .block-embed {
        flex-basis: 48%;
        max-width: 540px;
        margin-bottom: 0;
        margin-right: 2%;

        &.video {
        	padding-bottom: 28.125%; /* 16:9 */
        	padding-top: 25px;
        	height: 0;
        	margin: 1em 0;
        }
      }
      .block-text {
        flex-basis: 48%;
        // align-self: center;
      }
    }

    &.right {
      .container {
        flex-direction: row-reverse;
      }

      .block-embed {
        margin-left: 2%;
      }
    }
  }
}
