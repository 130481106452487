.staff-listing {
  padding: 4em 0;
}
.staff-detail {
  .container {
    @media screen and (min-width: $small-screen) {
      display: flex;
      justify-content: space-between;

      .staff-image {
        flex: 0 1 25%;
        margin-right: 2em;
      }
      .staff-content {
        flex: 1;
      }
    }
  }
}
.staff-image,
.staff-content {
  padding: 4em 0;
}
.staff-image {
  img {
    border-radius: 50%;
  }
}
.staff-filter {
  display: flex;
  border: 1px solid $hr-color;
  box-shadow: 0px 3px 30px rgba($color-black, .05), 
              0px 1px 8px rgba($color-black, .08);
  
  .staff-filter-header {
    flex: 0 1 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1em;
    border-right: 1px solid $hr-color;
  }
  .staff-filter-list {
    flex: 1;
    padding: 1em .5em;
  }
  
  h2 {
    margin: 0;
    font-size: 1.1em;
    font-family: $main-font;
  }

  ul {
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      margin: 5px 8px;
      padding: 0;
      list-style-type: none;
      list-style-image: none;
    }
  }
  a {
    &, &:link, &:visited {
      display: inline-block;
      background: $color-offwhite;
      border-radius: 30px;
      padding: .5em 1em;

      color: $font-color;
      line-height: 1.1;
      text-decoration: none;
    }
    &:hover, &:active, &:focus {
      background: $hover-color;
      color: $color-white;
    }
  }
}
.staff-department {
  padding: 2em 0;
}
.staff-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-gap: 25px;
  gap: 25px;
  align-items: start;
}

.staff-block {
  background: $color-offwhite;

  .staff-text {
    padding: 2em 1em;
    text-align: center;
    font-size: .9em;
  }
  .staff-name {
    a {
      &, &:link, &:visited {
        color: inherit;
        text-decoration: none;

        &:after {
          display: inline-block;
          content: '';
          background: url($image-path + 'icon-chevron-right.svg') no-repeat;
          background-size: 20px;
          height: 16px;
          width: 16px;
        }
      }
      &:hover, &:active, &:focus {
        color: $hover-color;
      }
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
}






