.interactive-map {
  width: 100%;
  max-width: $xlarge-screen;
  margin: 0 auto;

  &.bottom-spacing {
    margin-bottom: 3.5em;
  }
}
.map-bubble {

}

.local-cf-logo {
  margin-bottom: 1em;

  img {
    max-width: 400px;
    max-height: 200px;
  }
}