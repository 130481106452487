body.modal-open {
	overflow: hidden;
  position: fixed;
  width: 100%;
}

.menu {
 // align-self: stretch;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 1em;
  padding: .55em .95em;

  background: $link-color;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all .2s ease-out;

  color: $color-white;
  font-size: 1em;
  font-family: $main-font;

  .strokes {
    width: 18px;
    margin: 0 0 0 15px;

    .stroke {
      display: block;
      height: 2px;
      margin: 4px 0;
      background: $color-white;
      transition:  transform 0.3s, opacity 0.1s;
    }
  }

  &:hover, &:focus, &:active {
    cursor: pointer;
    background: $hover-color;
  }
  .openMenu & {
    background: $color-white;
  }
}
// animate the hamburger
.hide {
  opacity: 0;
}
.animate0 {
  transform: rotate(45deg) translateY(4px) translateX(4px);
}
.animate2 {
  transform: rotate(-45deg) translateY(-4px) translateX(4px);
}



.fullScreenMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
	min-height: 100%;

  background: $color-grey-dark;
  opacity: 0;
  transition: all 0.5s ease-in-out, opacity 0.5s;
	z-index: -1;
  outline: none;

  .menuContainer {
    display: inherit;
    opacity: 1;
    transform: translateY(0);
    animation: menufade .25s linear;
		padding: 80px 20vw;

		@media (max-width: $xlarge-screen) {
			padding: 80px 15vw;
		}

		@media (max-width: $medium-screen) {
			padding: 80px 70px;
		}

		@media (max-width: $xsmall-screen) {
			padding: 6vh 6vw;
		}
  }

	@keyframes menufade {
	  0% {
	    opacity: 0;
	    visibility: hidden;
	    transform: translateY(48px);
	  }
	  100% {
	    opacity: 1;
	    visibility: visible;
	    transform: translateY(0);
	  }
	}
}

.fullScreenMenu.show {
  opacity: 1;
	background-color: rgba($color-offwhite, .99);
  transition: all 0.5s ease-in-out, visibility 0.5s;
	z-index: 4;
  display: block;

  ul {
    margin: 0;
    padding: 0;
    position: relative;

    li {
      margin: 0 0 .5em;
      padding: 0;
      list-style-type: none;
      list-style-image: none;
      background: none;
    }

    a {
      &, &:link, &:visited {
        color: $font-color;
        text-decoration: none;
				font-size: .9375rem;
      }
      &:hover, &:active, &:focus {
        color: $hover-color;
      }
    }
  }

	.menuContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

    color: $font-color;
		font-family: $accent-font;
    font-size: 1em;

    @media screen and (min-width: $small-screen) {
      display: flex;
    }
  }
  .mobile-menu {

    @media screen and (min-width: $small-screen) {
      flex: 1;
      border-right: 1px solid $hr-color;
      margin-right: 5%;
			padding-top: 40px;

      font-size: .975em;
    }
		@media screen and (min-width: $large-screen) {
			padding-top: 75px;
		}

    .root-group {
      column-count: 2;
      column-gap: 2em;
      -webkit-column-gap: 2em;

      > li  {
        // top level
        display: block;
        page-break-inside: avoid;
        margin-bottom: 40px;

        > a {
          display: block;
          margin-bottom: .5em;

					cursor: default;

          color: $link-color !important;
          font-size: 1em;
          font-family: $main-font;
        }
      }
    }

    .dropdown-menu.level1 ul { display: none; }
  }
}

.mobile-sidebar {
  flex: 0 1 33%;
	padding-top: 40px;

	@media screen and (min-width: $large-screen) {
		padding-top: 75px;
	}
}
.quicklinks {
  .link-buttons {
    font-size: 0.9rem;

    a,
    a:link,
    a:visited {
      &.button {
        display: block;
        margin: 0 0 10px 0;
        padding: 1em 1.25em;

        background: $link-color;
        border-radius: 0px;
        border: 0px;

        text-align: center;
        color: $color-white !important;
      }
    }
    a:hover, a:active, a:focus {
      &.button {
        background: $hover-color;
      }
    }
    li:nth-child(2) {
      a, a:link, a:visited {
        &.button {
          background: $color-white;
          color: $link-color !important;
          box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05), 2px 2px 72px rgba(0, 0, 0, 0.09);
        }
      }
      a:hover, a:active, a:focus {
        &.button {
          background: $hover-color;
          color: $color-white !important;
        }
      }
    }
  }
  ul.initiative-links {
    padding: 1em 0 2em;

    li {
      margin: -1px 0 0;
      padding: 0;
    }
    a {
      &, &:link, &:visited {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 1em 0;

        border-top: 1px solid $hr-color;
        border-bottom: 1px solid $hr-color;

        line-height: 1.25;

        .init-img {
          flex: 0 1 40px;
          margin-right: 15px;
          text-align: center;
        }

        &:after {
          content: '';
          background: url($image-path + 'icon-right-red.svg') no-repeat center center;
          background-size: contain;
          height: 15px;
          flex: 0 0 15px;

          display: inline-block;
          margin-left: auto;
        }
      }
      &:hover, &:active, &:focus {}
    }
  }

}
.close-menu {
  position: absolute;
  top: 1em;
  right: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 1em;
  padding: .55em .95em;

  background: $link-color;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all .2s ease-out;

  color: $color-white;
  font-size: 1em;
  font-family: $main-font;

  z-index: 6;

  // animation: slowfade .5s linear;

  &:hover, &:active, &:focus {
    box-shadow: 0 1px 12px 0 rgba($color-black, .1),
  }

  img {
    margin: 0;
  }
  span, img {
    pointer-events: none;
  }
}
