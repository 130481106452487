.hero {
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  opacity: 0;
  animation: fade-in .75s linear;
  animation-delay: .5s;
  animation-fill-mode: forwards;

  .hero-mask {
    position: relative;
    height: 25vh;
    min-height: 500px;

    display: flex;
    flex-direction: column;

    background: $hero-gradient-mobile;

    @media screen and (min-width: $medium-screen) {
      height: calc(100vh - 130px);
      background: $hero-gradient;
    }

    @media screen and (max-width: $xsmall-screen) {
      height: auto;
    }
  }

  .hero-text {
    width: 90%;
    // max-width: $home-width;
    margin: 0 auto;
    padding: 1.5em 0 2.5em;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: $color-white;
    font-size: 1.375em;
    line-height: 1.4;
    text-shadow: 0px 2px 4px rgba($color-black, .5);

    @media screen and (min-width: $large-screen) {
      .slide {
        width: 42% !important;
      }
    }

    .hero-img {
      display: none;

      opacity: 0;
      animation: fade-in 3s linear;
      animation-delay: .75s;
      animation-fill-mode: forwards;
    }

    h1 {
      margin: 0 0 25px;

      color: inherit;
      font-size: 2.4em;
      line-height: 1.1;
      letter-spacing: .5px;

      &:after {
        margin: 25px 0 0;
        height: 7px;
        width: 100px;
      }
    }
    .hero-desc {
    }
    .button {

    }
  }
}
