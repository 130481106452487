.secondary-nav {
  background: $color-offwhite;
  border-bottom: 1px solid $hr-color;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: .75em 0;

    font-size: .9em;
    font-family: $accent-font;
  }
}
.breadcrumbs {  
  display: none;
  
  @media screen and (min-width: $medium-screen) {
    display: block;
  }

  a, a:link, a:visited {
    display: inline-block;
    font-weight: inherit;
  }
  a:hover, a:active, a:focus {}

  ul {

    padding: 0;
    display: flex;
    align-items: center;
    line-height: 1;

    li {
      margin: 0 4px;
      padding: 0;
      list-style-type: none;
      list-style-image: none;

      display: flex;
      align-items: center;

      &:after {
        display: inline-block;
        content: '';
        background: url($image-path + 'icon-chevron-right.svg') no-repeat;
        background-size: 20px;
        background-position: center;
        height: 16px;
        width: 16px;
        margin-left: 7px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}