#hero-rotator {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      list-style-image: none;
      margin: 0;
      padding: 0;
    }
  }

  .slide {
    display: none;
    visibility: hidden;

    width: 100%;

    &.current {
      visibility: visible;
      display: block;
    }
  }
  .slidenav {
    position: absolute;
    bottom: 1em;
    left: 5vw;
    max-width: 33%;

    display: flex;
    justify-content: flex-start;

    //padding: 1.25em 0;
    display: none;

    li {
      padding: 0.25em;
    }
    button {
      cursor: pointer;
      display: block;
      height: 18px;
      width: 18px;

      border: 1px solid $color-white;
      border-radius: 50%;
      background: $color-white;
      color: $color-white;
      font-size: 1px;
      line-height: 1px;

      &.current {
        border-color: $link-color;
        background: $link-color;
        color: $link-color;
        opacity: 1;
      }
      &:hover, &:focus, &:active {
        border-color: $hover-color;
        background: $hover-color;
        color: $hover-color;
      }
    }
  }

  .controls {
    position: absolute;
    bottom: 1em;
    right: 5vw;
    max-width: 33%;

    display: flex;
    justify-content: flex-end;

    button {
      -webkit-appearance: none !important;
      cursor: pointer;

      height: 40px;
      width: 40px;
      margin: 0 5px;

      background: $color-white;
      border: 1px solid $hr-color;
      border-radius: 50%;

      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        display: block;
        content: '';
        background-repeat: no-repeat;
        background-size: 18px 18px;
        width: 18px;
        height: 18px;
      }

      &.btn-prev:before {
        background-image: url($image-path + 'icon-previous.svg');
      }

      &.btn-next:before {
        background-image: url($image-path + 'icon-next.svg');
      }
      &[data-start="true"]:before {
        background-image: url($image-path + 'icon-play.svg');
        margin-left: 2px;
        background-size: 25px 25px;
        width: 25px;
        height: 25px;
      }
      &[data-stop="true"]:before {
        background-image: url($image-path + 'icon-stop.svg');
      }
    }
  }
}
