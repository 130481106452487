.subnav-holder {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: relative;

  h2.subnav-header {
    margin: 0;
    
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
  .subnav-toggle {
    display: inline-block;
    width: 200px;

    margin-left: 1em;

    > a {
      display: flex;
      
      background: $color-white;
      border: 1px solid $hr-color;
      border-radius: 5px;
      
      font-weight: inherit;
      text-decoration: none;

      &:hover, &:active, &:focus {
        color: $hover-color;

      }
    }

    span.toggle-text {
      flex: 1;
      display: inline-block;
      position: relative;
      
      white-space: nowrap;
      overflow: hidden;

      padding: .5em 1em;
      line-height: 1;

      &:after {
        display: block;
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        
        height: 100%;
        width: 25px;

        background-image: linear-gradient(to left, $color-white, transparent);
      }
    }
    span.toggle-arrow {
      flex: 0 0 40px;
      
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 16px;
        width: 16px;
      }
    }
  }
}
.subnav {
  display: none;
  z-index: 99;

  position: absolute;
  top: 90%;
  left: auto;
  right: auto;

  background: $color-white;
  border: 1px solid $hr-color;
  border-top: 0px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
  
  padding: .5em 1.5em 1em;
  width: 200px;

  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0.25em 0;
      list-style-type: none;
      list-style-image: none;
    }
    ul {
      margin-left: 1em;
      font-size: .9em;
    }
  }
  a, a:link, a:visited {
    color: $font-color;
    font-size: 1em;
    font-weight: normal;
    text-decoration: none;

    &.active {
      color: $link-color;
    }
  }
  a:hover, a:active, a:focus {
    color: $hover-color;
  }
}