// BREAKPOINTS
$xsmall-screen: em(500);
$small-screen: em(700);
$medium-screen: em(950);
$large-screen: em(1200);
$xlarge-screen: em(1600);

// WIDTHS
$small-width: rem(500);
$limited-width: rem(800);
$max-width: rem(1140);
$home-width: rem(1100);
$extended-width: rem(1440);

// PATHS
$image-path: "/images/";
$font-path: "/fonts/fonts/";

// COLORS
$color-red: #BD141D;
$color-red-light: #CB2128;

$color-grey-light: #E5E5E5;
$color-grey-mid: #887D77;
$color-grey: #6C625C;
$color-grey-dark: #4A433E;

$color-offwhite: #F9F7F7;
$color-white: #fff;
$color-black: #000;

// HERO
$hero-gradient: linear-gradient(to right, rgba($color-black, .75) 0%, rgba($color-black, .0) 75%);
$hero-gradient-mobile: linear-gradient(to right, rgba($color-black, .5) 0%, rgba($color-black, .5) 100%);

// BACKGROUNDS
$bkgd-body: $color-white;

// CORE FONTS
$main-font: 'Heebo', sans-serif;
$accent-font: 'Public Sans', sans-serif;


// FONT STYLES
$font-color: $color-grey;

$font-size: 1em;
$min-font: 14px;
$max-font: 16.5px;
$main-lineheight: 1.6;

$link-color: $color-red;
$hover-color: $color-red-light;

$hr-color: $color-grey-light;
$border-color: $hr-color;

$highlight-color: rgba($color-red, 0.2);
