.button p {
  padding: 0;
}
a.button, .button a,
input.button, button.button,
.freeform-column button  {
  &, &:link, &:visited {
    display: inline-block;
    outline: none;

    padding: 1em 2.5em;
    margin: 0px;

    background-color: $color-white;
    border: 1px solid $color-grey-dark;

    transition: transform .2s;
    text-align: center;

    color: $color-grey-dark;
    font-size: 1.05rem;
    font-weight: normal;
    line-height: 1.1;
    text-decoration: none !important;
    text-shadow: none;
  }

  &:hover, &:active, &:focus {
    color: white;
    background: $color-red;
    transition: .2s all;
    box-shadow: 0px 1px 5px rgba($color-black, .15),
                0px 2px 30px rgba($color-black, .08);
  }
  &:active, &:focus {
    box-shadow: 0px 1px 2px rgba($color-black, .3),
                0px 2px 15px rgba($color-black, .1);
  }
}
a.button.rounded, .button.rounded a,
input.button.rounded, button.button.rounded {
  border-radius: 35px;
}

.lightGrey a.button, .lightGrey .button a,
a.button.dark-btn, .button.dark-btn a,
input.button.dark-btn, button.button.dark-btn {
  background: $color-red-light;
  border-color: $color-red-light;
  color: $color-white;

  &:hover, &:active, &:focus {
    background: $color-red ;
    color: $color-white;
  }
}
.special-link.button a {
  &, &:link, &:visited {
    border-color: $color-white;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05), 2px 2px 72px rgba(0, 0, 0, 0.09);

    color: $link-color;
  }

  &:hover, &:active, &:focus {
    background: $color-red-light;
    color: $color-white;
  }
}

.white-btn a {
  &, &:link, &:visited {
    border-color: $color-white;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05), 2px 2px 72px rgba(0, 0, 0, 0.09);

    color: $link-color !important;
  }

  &:hover, &:active, &:focus {
    background: $color-red-light;
    color: $color-white !important;
  }
}


.text-button p {
  padding: 0;
}
a.text-button, .text-button a,
input.text-button, button.text-button {
  &, &:link, &:visited {
    display: inline-block;
    font-size: 1.05em;
    text-decoration: none;
    border-bottom: 2px solid $link-color;
  }
  &:hover, &:active, &:focus {
    border-color: $hover-color;
  }
}

a.arrow-link {
  text-decoration: none;
}
.arrow-link {
  font-size: .9em;
  font-family: $accent-font;
  font-weight: normal;
  text-decoration: none;

  &:before {
    content: '';
    background: url($image-path + 'icon-chevron-right.svg') no-repeat;
    background-size: 6px 12px;
    height: 12px;
    width: 6px;

    display: inline-block;
    transition: transform .2s;
    margin-right: 8px;
    margin-bottom: -1px;
  }

  &:hover, &:active, &:focus {
    &:before {
      transform: translateX(2px);
    }
  }
}
