.fund-listing {
  .list-container {
    border: 1px solid $hr-color;
    box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.05);
    position: relative;
    margin-bottom: 3em;
  }

  /* FILTERS */
  .filters {
    padding: 2em 2em 1em;
    margin: 0;
    border-bottom: 1px solid $color-grey-light;
    border-radius: 0px;

    .directorySearch {
      display: flex;
      justify-content: space-between;
      align-self: center;
      flex-wrap: wrap;
    }

    .keywordSearch {
      flex: 0 1 100%;
      margin: 0 0 1em 0;
    }
    .topicSearch, .sortSearch, .locationSearch, .impactSearch {
      flex: 0 1 100%;
      margin: 0 0 1em 0;

      @media screen and (min-width: $xsmall-screen) {
        flex: 0 1 31%;
      }
      @media screen and (min-width: $medium-screen) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    label {
      display: block;
      margin: 0 0 .25em 0;

      font-size: .95em;
      font-family: $accent-font;
      text-transform: uppercase;
      white-space: nowrap;

      @media screen and (min-width: $medium-screen) {
        margin: 0 1em 0 0;
      }

      a.label-tip {

        svg {
          width: 16px;
          height: auto;
          transform: translate(1px, -2px);
          path {
            fill: $color-grey;
            transition: .2s all ease-in;
          }
        }

        &:hover {
          svg {
            path {
              fill: $color-red;
            }
          }
        }
      }
    }

    select {
      display: block;
      flex: 1;
      width: 100%;
      height: 44px;
      margin: 0;
      padding: 0 1em;

      -webkit-appearance: none;
      -moz-appearance: none;
      background: $color-white url($image-path + 'icon-dropdown.svg') no-repeat;
      background-position: calc(100% - 12px) 50%;
      border: 1px solid $hr-color;

      color: $link-color;
    }

    input[type=text], input[type=search] {
      -webkit-appearance:none;
      width: 100%;
      height: 44px;
      padding: 0 1em 0 2.5em;
      margin: 0;

      background: $color-offwhite url($image-path + "icon-keyword-search.svg") no-repeat;
      background-position: 15px 10px;
      border: 1px solid $hr-color;
      border-radius: 25px;

      transition: all .1s ease-out;

      &::placeholder {
        color: rgba($color-black, .33);
      }
      &:focus, &:active {
        outline: none;
        border: 1px solid $color-black;
        box-shadow: 0 1px 12px 0 rgba($link-color, .1),
      }
    }
  }

  /* LISTING */
  .noResults {
    padding: 2em;
    text-align: center;
  }

  .directoryCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //align-items: flex-start;

    position: relative;
  }

  .fundCard {
    flex: 0 0 100%;

    background: $color-white;

    color: $color-grey-dark;
    font-size: .9em;
    line-height: 1.5;

    &:nth-of-type(odd) {
      background: $color-offwhite;
    }

    h3 {
      margin: 0;

      a {
        &, &:link, &:visited {
          color: inherit;
          font-weight: inherit;
          font-family: $accent-font;
          border-bottom: 1px solid $link-color;
          text-decoration: none;
        }

        &:hover, &:active, &:focus {
          color: $link-color;
          text-decoration: none;
        }
      }
    }

    p {
      padding: 0 0 .75em;
    }
  }
  .card-content {
    padding: 2em 2em 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fund-info {
    flex: 1;
  }
  .fund-button {
    margin-left: 1em;

    .button {
      padding: .5em 1em;
    }
  }



  .type {
    margin: 0 0 .25em 0;

    color: $link-color;
    font-size: .85em;
    font-family: $accent-font;
    text-transform: uppercase;
  }

  .fundTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin: 0 0 .75em;
  }

  .tooltip {
    cursor: pointer;
    margin-left: 5px;
    position: relative;

    //Tooltip text container
    &::before {
      position: absolute;
      top: -47px;
      left: -60px;
      background-color: rgba($color-black, .95);
      border-radius: 3px;
      color: white;
      content: attr(aria-label); //This pulls in the text from the element with the tooltip
      padding: 10px;
      text-transform: none;
      transition: all 0.2s ease;
      width: 120px;
    }

    //Tooltip arrow
    &::after {
      position: absolute;
      top: -12px;
      left: 9px;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid $color-black;
      content: " ";
      font-size: 0;
      line-height: 0;
      margin-left: -3px;
      width: 0;
    }

    //Setting up the transition
    &::before,
    &::after {
      color: #fff;
      font-family: $accent-font;
      font-size: 12px;
      opacity: 0;
      pointer-events: none;
      text-align: center;
    }

    //Triggering the transition
    &:hover::before,
    &:hover::after {
      opacity: 1;
      transition: all 0.2s ease;
    }

    img {
      display: inline-block;
    }
  }



  /* PAGINATION */
  .pager {
    flex-basis: 100%;
    padding: 1.5em 2em;
    border-top: 1px solid $hr-color;

    display: flex;
    justify-content: space-between;

    @media (max-width: $medium-screen) {
      flex-wrap: wrap;
      justify-content: center;
    }


    .firstPage, .lastPage {
      a {
        &, &:link, &:visited {
          background: $link-color;
          color: $color-white;
          border-radius: 3px;
          padding: .5em 1em;
          text-decoration: none;
        }

        &:hover, &:active, &:focus {
          background: $hover-color;
        }
      }
    }

    .middlePages {
      align-self: center;
      width: calc(100% - 122px);
      text-align: center;

      @media (max-width: $medium-screen) {
        order: 1;
        margin-bottom: 12px;
        width: 100%;
      }

      a {
        &, &:link, &:visited {
          padding: 0 4px;
          text-decoration: none;

          img {
            height: 18px;
          }
        }

        &:hover, &:active, &:focus {
          img {
            opacity: .75;
          }
        }
      }
    }

    .firstPage {
      @media (max-width: $medium-screen) {
        order: 2;
        margin-right: 6px;
      }
    }

    .lastPage {
      align-self: flex-end;

      @media (max-width: $medium-screen) {
        order: 3;
        margin-left: 6px;
      }
    }
  }

  #indicator .loader {
    display: none;
  }
  #indicator.loading .loader {
    display: inline;
  }
  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;

    background: rgba($color-white, .95);
    text-align: center;
    padding: 50px;
  }
  .loading-text {
    display: block;
    text-transform: uppercase;
    color: $color-black;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $highlight-color;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $highlight-color transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
