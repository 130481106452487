.mast {
  transition: all .2s linear;

  @media (min-width: $small-screen) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    background: $color-white;
    border-bottom: 1px solid $hr-color;

    transition: .2s ease-in-out;

    //overflow: hidden;
  }

  @media screen and (min-width: $medium-screen) {
    &.not-at-top {

      .container {
        padding: .4em 0;
        transition: all .2s linear;
      }
      .initiatives {
        margin-top: -32px;
        transition: all .2s linear;
      }
      .logo img {
        width: 190px;
        transition: all .2s linear;
      }
    }
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      list-style-image: none;
    }
  }
}
.initiatives {
  display: none;

  @media screen and (min-width: $small-screen) {
    display: block;
  }
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;

    background-image: linear-gradient(to left, rgba($color-grey-light, 1) 0%, rgba($color-grey-light, 0) 85%);
  }

  .container {
    width: 90%;
    max-width: inherit;
    padding: .5em 0;

    display: flex;
    justify-content: flex-end;
  }

  svg {
    height: 14px;
    width: auto;
    margin-right: 8px;

    path:not(.exclude) {
      fill: $color-grey-mid;
    }
  }

  a {
    &, &:link, &:visited {
      display: flex;
      align-items: center;
      margin: 0 13px;
      transition: .2s ease-in-out;

      color: $color-grey-mid;
      font-size: 0.725em;
      font-family: $accent-font;
      text-decoration: none;
      line-height: 1;
      text-transform: uppercase;
    }
    &:hover, &:active, &:focus {
      color: $hover-color;
      text-decoration: underline;
    }
  }
}
.header {
  .container {
    width: 90%;
    max-width: inherit;
    padding: 1em 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    flex: 0 1 240px;
    margin-right: auto;

    @media (max-width: $xsmall-screen) {
      margin-right: 20px;
    }

    a, a:link, a:visited {
      border: 0;

      &:hover {
        opacity: .85;
      }
    }
    img {
      width: 240px;
      height: 65px;
    }
  }
  .search-btn {
    --webkit-appearance: none;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;

    background: none;
    border: 1px solid $hr-color;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 42px;
    width: 42px;

    &:hover {
      border: 1px solid $hover-color;
    }

    img, svg { pointer-events: none; }
  }
  .admin-btns {
    display: none;

    @media screen and (min-width: $medium-screen) {
      display: block;
      margin-right: 1.25em;
    }

    li:nth-child(2) {
      margin-left: 1.25em;
      ::before {
        content: '';
        position: absolute;
        background-image: url($image-path + 'icon-lock-gray.svg');
        background-size: 12px 12px;
        width: 12px;
        height: 12px;
        transform: translate(-18px, 6px);
      }
    }

    a {
      &, &:link, &:visited {
        display: block;
        margin: 0 13px;
        transition: .2s ease-in-out;

        color: $color-grey-dark;
        font-size: .95em;
        font-family: $accent-font;
        text-decoration: none;
      }
      &:hover, &:active, &:focus {
        color: $hover-color;
        text-decoration: underline;
      }
    }
  }
}
