main {

  @media (min-width: $small-screen) {
    padding-top: 130px;
  }
}

.content-head {
  border-bottom: 1px solid $hr-color;
  position: relative;

  .article-list & {
    margin-bottom: 4em;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;

    height: 150px;
    width: 75px;

    @include graphic-bkgd;
  }

  .container {
    padding: 3em 0;
    max-width: $limited-width;
    text-align: center;
  }

  p {
    margin: 0;
    padding: 0;
  }
}
