.feature-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-offwhite;

	@media all and (min-width: $medium-screen) {
    flex-direction: row;
	}

	.feature-image {
		flex: 0 1 50%;
    width: 100%;
		height: 500px;
		margin: 0;
		overflow: hidden;

    @media all and (max-width: $medium-screen) {
      height: 400px;
    }

    @media all and (max-width: $small-screen) {
      height: 300px;
    }

    @media all and (max-width: $xsmall-screen) {
      height: 250px;
    }

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
  }

	.feature-text {
		flex: 0 1 50%;
		max-width: 560px;
    line-height: 1.5;
    padding: 1em;

    @media all and (max-width: $medium-screen) {
      width: 90%;
      padding: 40px 5%;
      max-width: 100%;
    }
  }

  h2 { color: inherit; }

  &.lightGrey {
    background: $color-offwhite;
    color: $color-grey-dark;
  }
  &.red {
    background-color: $color-red-light;
    @include graphic-bkgd;
    color: $color-white;
  }

	&.left {
    @media all and (min-width: $medium-screen) {
      .feature-text {
        padding-left: 80px;
      }
    }
	}
	&.right {
		@media all and (min-width: $medium-screen) {
			flex-direction: row-reverse;
      .feature-text {
        padding-right: 80px;
      }
		}
	}
}


.special-row {
  position: relative;
  margin-bottom: 2em;

  .container {
    padding: 2.5em 0;
    max-width: $home-width;
  }

  .special-image {
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
  }
  .special-text {
    background: $color-offwhite;
    padding: 2em;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media all and (min-width: $small-screen) {
    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;

      height: 150px;
      width: 75px;

      @include graphic-bkgd;
    }
    .container {
      display: flex;
      justify-content: space-between;

      .special-image {
        flex: 0 1 45%;
        max-width: 420px;
        position: relative;
        top: -50px;
        // box-shadow: 20px 20px 0 0 rgba($color-black, .05);
      }
      .special-text {
        flex: 1;
        margin: 0;
        padding: 4em;

        padding-left: 150px;
        margin-left: -75px;
      }
    }

    &.right {
      &:after {
        right: 0;
        left: auto;
      }
      .container {
        flex-direction: row-reverse;
      }
      .special-text {
        margin: 0;
        padding: 4em;

        margin-right: -75px;
        padding-right: 150px;
      }
    }
  }
}
