.filters {
  border-radius: 8px;
  box-shadow: 0px 3px 30px rgba($color-black, .05), 0px 1px 8px rgba($color-black, .08);
  padding: 1em;
  margin-bottom: 2em;

  .directorySearch {
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;

    @media (max-width: $medium-screen) {
      flex-wrap: wrap;
    }
  }

  select {
    display: block;
    width: 240px;
    height: 44px;
    margin: 0 12px;
    padding: 0 1em;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: $color-white url($image-path + 'icon-dropdown.svg') no-repeat;
    background-position: calc(100% - 12px) 50%;
    border: 1px solid $hr-color;
    border-radius: 3px;
    box-shadow: 0 1px 6px 0 rgba($link-color, .1);

    @media (max-width: $medium-screen) {
      width: 48%;
      margin: 0 0 12px 0;
    }
  }

  .keywordSearch {
    width: 240px;
    margin: 0 12px 0 0;

    @media (max-width: $medium-screen) {
      width: 90%;
      margin: 0 0 12px 0;
    }

    input[type=text], input[type=search] {
      -webkit-appearance:none;
      width: calc(100% - 14px);
      height: 44px;
      padding: 0 0 0 12px;
      margin: 0;

      background: $color-white;
      border: 1px solid $hr-color;
      border-radius: 3px;

      transition: all .1s ease-out;

      &::placeholder {
        color: rgba($color-black, .33);
      }
      &:focus, &:active {
        outline: none;
        border: 1px solid $color-black;
        box-shadow: 0 1px 12px 0 rgba($link-color, .1),
      }
    }
  }

  button.applyFilter {
    &, &:link, &:focus {
      background: $link-color;
      color: $color-white !important;

      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 42px;

      border: 1px solid transparent;
      border-radius: 3px;
      outline: 0;
      transition: all .2s;


      @media (max-width: $medium-screen) {
        margin: 0 auto;
        padding: 0 24px;
        width: 50%;
        text-align: center;
        justify-content: center;
      }
    }
    &:hover, &:focus, &:active {
      cursor: pointer;
      background: $hover-color;
      box-shadow: 0 1px 12px 0 rgba($link-color, .1);
    }
  }

	a.clearButton {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 0 0 12px;
    padding: 0 1em;

    height: 42px;

    border: 1px solid transparent;
    border-radius: 3px;

    color: $link-color;
    white-space: nowrap;
    transition: all .2s;
    text-decoration: none;

    &:hover, &:focus, &:active {
      border: 1px solid $color-black;
      color: $link-color;
      box-shadow: 0 1px 12px 0 rgba($link-color, .1);
    }

    @media (max-width: $medium-screen) {
      width: 90%;
      margin: 0;

      &:hover, &:focus, &:active {
        border: 1px solid transparent;
        color: $hover-color;
        box-shadow: none;
      }
    }
	}
}
.listing-results {
  margin-bottom: 2em;
}

.pagination {
  padding: 1em 0;
  margin-bottom: 2em;
  border-top: 1px solid $hr-color;

  display: flex;
  justify-content: space-between;

	@media (max-width: $medium-screen) {
		flex-wrap: wrap;
		justify-content: center;
	}


	.firstPage, .lastPage {
		a {
      &, &:link, &:visited {
        background: $link-color;
        color: $color-white;
        border-radius: 3px;
        padding: .5em 1em;
				text-decoration: none;
      }

			&:hover, &:active, &:focus {
				background: $hover-color;
			}
		}
	}

	.middlePages {
		align-self: center;
		width: calc(100% - 122px);
		text-align: center;

		@media (max-width: $medium-screen) {
			order: 1;
			margin-bottom: 12px;
			width: 100%;
		}

		a {
      &, &:link, &:visited {
        padding: 0 4px;
				text-decoration: none;
        
        img {
          height: 18px;
        }
      }

			&:hover, &:active, &:focus {
        img {
          opacity: .75;
        }
      }
		}
	}

	.firstPage {
		@media (max-width: $medium-screen) {
			order: 2;
			margin-right: 6px;
		}
	}

	.lastPage {
		align-self: flex-end;

		@media (max-width: $medium-screen) {
			order: 3;
			margin-left: 6px;
		}
	}
}
