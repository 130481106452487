
$footer-bkgd: $color-offwhite;

.footer {
  background: $footer-bkgd;
  border-top: 1px solid $hr-color;

  font-size: .9em;

  .divider {
    display: inline-block;
    margin: 0 3px;
    color: $footer-bkgd;
  }
  p {
    margin: 0;
    padding: 0 0 .5em;
  }
  ul {
    li {
      margin: 0;
      padding: 0 0 .5em;
      list-style-type: none;
      list-style-image: none;
    }
  }
  a {
    &, &:link, &:visited {
      color: inherit;
      text-decoration: none;
    }
    &:hover, &:active, &:focus {
      color: $hover-color;
      text-decoration: underline;
    }
  }
}
.footer-top {
  padding: 4em 0 1em 0;

  .container {
    text-align: center;

    @media screen and (min-width: $small-screen) and (max-width: $medium-screen) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      grid-gap: 2em;
      gap: 2em;

      text-align: left;

      .footer-logo {
        grid-row: 1;
        grid-column: 2;

        display: flex;
        align-items: center;
        justify-content: center;
      }
      .social {
        grid-row: 2;
        grid-column: 2;
      }
    }

    @media screen and (min-width: $medium-screen) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      grid-gap: 2em;
      gap: 2em;

      text-align: left;
    }
  }

}
.footer-col  {
  margin-bottom: 2em;
}
.footer-logo {
  img {
    max-width: 240px;
  }
}
.social {
  p {
    padding: 0 0 1em;
  }
  ul {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $medium-screen) {
      justify-content: flex-start;
    }

    li {
      margin: 0 8px;

      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }
    }
  }
  img, svg {
    height: 20px;
    width: 20px;
  }
}
.enews-block {
  display: flex;
  flex-direction: column-reverse;

  p { font-size: .9em;}
}
.enews-form {
  width: 100%;
  display: flex;
  margin-bottom: 1em;

  input[type="text"], input[type="email"] {
    flex: 1;
    height: 50px;
    padding: 0 1em;

    background: $color-white;
    border: 1px solid $color-white;
    border-right: 0px;
    outline: none;

    color: $font-color;
    font-family: $main-font;
    font-size: $font-size;

    &:focus {
      background: $color-white;
      border-color: $color-red;

      ~ button {
        border-color: $color-red;
      }
    }

    &::placeholder {
      color: rgba($font-color, .5);
    }
  }

  button[type="submit"] {
    flex: 0 1 48px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    background: $color-white;
    box-shadow: none;
    border: 1px solid $color-white;
    border-left: 0px;

    img, svg {
      height: 18px;
      width: 18px;
      transform: translate(-6px,1px);
      transition: all 0.2s ease;
    }

    &:hover, &:active, &:focus {
      img, svg {
        transform: translate(-2px,1px);
        cursor: pointer;
      }
    }
  }
}
.footer-btm {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    border-top: 1px solid $hr-color;
    padding: 2em 0;

    text-align: center;
    font-size: .9em;

    @media screen and (min-width: $medium-screen) {
      flex-direction: row;
    }
  }
  .mtcf-logo {
    img, svg {
      max-width: 165px;
    }
  }
}
