.banner {
  background-color: $color-offwhite;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .banner-mask {
    height: 200px;

    display: flex;
    align-items: flex-end;

    @media screen and (min-width: $medium-screen) {
      height: 300px;
    }
  }

  &.short {
    // @include graphic-bkgd;

    .banner-mask {
      //height: 100px;
      height: 1px;
      background-color: $hr-color;
    }
  }

  .container {
    max-width: $extended-width;
    padding: 1.5em 0;

    text-align: center;
    color: $color-white;
    text-shadow: 0px 4px 48px rgba($color-black, .25), 0px 1px 4px rgba($color-black, .5);

    p {
      margin: 0;
      padding: 0;
    }
  }

  h1 {
    margin: 0;
    color: inherit;
  }
}


.text-banner {
  text-align: center;

  .container {
    padding: 3em 0;

    .article-detail & {
      width: 100%;
      max-width: initial;
      border-bottom: 1px solid $hr-color;
    }
  }
}
