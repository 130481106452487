// Header Search
.overlay {
  height: 100%;
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;

  opacity: 0;
	z-index: -1;

  backdrop-filter: blur(7px);
  background-color: rgba(white, .9);

  .modal-open & {
    z-index: 999;
    opacity: 0;
    animation: fade-in .2s linear;
    animation-fill-mode: forwards;
  }

  .closebtn {
    position: absolute;
    top: 62px;
    right: calc(3vw + 138px);

    border: 1px solid $hover-color;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    margin-right: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all .2s ease-in-out;
    background: white;
    z-index: 6;
    cursor: pointer;

    img { pointer-events: none; }

    &:hover, &:active, &:focus {
      border: 1px solid $color-black;
      box-shadow: 0 1px 12px 0 rgba($color-black, .1),
    }
  }
}

.overlayContent {
  position: absolute;
  top: 50%;
	width: 60%;
	left: 20%;
  transform: translateY(-50%);
}


.header-search {
  display: flex;
  justify-content: center;

  input[type=search] {
    -webkit-appearance: none;
    border: 1px solid $color-black;
		border-radius: 3px;
    width: 100%;
    height: 72px;

		margin: 0;
		padding: 4px 72px 0;

    transition: all .1s ease-out;

		font-size: 24px;
		font-family: $main-font;
	  line-height: 30px;
    font-weight: 400;

    &::placeholder {
      padding: 4px 0 0 0;
      color: $color-grey;
    }
    &:focus, &:active {
      outline: none;
      border: 1px solid $color-black;
			box-shadow: 0 1px 12px 0 rgba($color-black, .1);
    }
  }

  button.submitSearch {
    -webkit-appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    left: 16px;
    top: 22px;

    img {
      pointer-events: none;
			height: 30px;
		}
  }
}


// Content Search
.search-form {
  display: flex;

  input, button {
    background: transparent;
    border: 1px solid $color-grey-dark;
    padding: .75em 1.5em;

    color: $color-grey-dark;
  }
  input {
    flex: 1;
    border-right: 0px;

    &::placeholder {
      color: rgba($color-grey-dark, .9);
    }
  }
  button {
    flex: 0 1 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    border-left: 0px;

    cursor: pointer;
    transition: .2s all ease-in;

    img, svg {
      height: 20px;
      width: 20px;

      path { fill: $font-color; }
    }

    &:hover, &:active, &:focus {
      background: $hover-color;
      svg {
        path { fill: $color-white; }
      }
    }
  }
}

.search-results {
  li {
    margin: 0;
    padding: 1em 0;
    list-style-type: none;
    list-style-image: none;
    border-bottom: 1px solid $hr-color;

    &:last-child {
      border: 0px;
    }
  }
  a, a:link, a:visited {
    font-weight: bold;
  }
}
