.full-content {
  position: relative;
  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;

    height: 150px;
    width: 75px;

    @include graphic-bkgd;
  }

  &.lightGrey { background-color: $color-offwhite; }

  .container {
    padding: 3em 0;
    max-width: $limited-width;
  }
}


.callout {
  display: block;
  padding: 2em 1.5em;
  margin: 1em 0 2.5em;

  background-color: $color-red-light;
  @include graphic-bkgd;

  text-align: center;
  color: $color-white;
  font-size: 1.3em;
  line-height: 1.25;

  &.last-block {
    margin: 1em 0 0;
  }

  p:last-child {
    padding: 0;
  }
  a {
    &, &:link, &:visited {
      color: inherit;
      text-decoration: none;
    }
    &:hover, &:active, &:focus {
      color: $color-offwhite;
      text-decoration: underline;
    }
  }
}


.contact-cta {
  display: block;
  padding: 2em 1.5em;
  margin: 1em 0 2.5em;

  background-color: darken($color-offwhite, 5%);
  border-top: 1px solid darken($hr-color, 3%);
  border-bottom: 1px solid darken($hr-color, 3%);

  .container {
    display: grid;
    grid-template-columns: 150px 1fr 260px;
    grid-gap: 1.5em;
    align-items: center;

    @media (max-width: $small-screen) {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }

  picture {
    margin: 0;
    padding: 0;

    img {
      max-width: 150px;
      border-radius: 150px;
    }
  }

  .contact-cta-text {
    h3 {
      margin: 0 0 8px;
    }

    p {
      padding: 0;
    }
  }

  .contact-cta-buttons {
    display: flex;
    flex-flow: column;
    gap: 1em;

    a {
      &, &:link, &:visited {
        padding: 1em;
        border-color: transparent;
        background: $color-red;
        box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05), 2px 2px 72px rgba(0, 0, 0, 0.09);
        color: white !important;
      }

      &:hover, &:active, &:focus {
        background: $color-red-light;
      }
    }
  }

  &.last-block {
    margin: 1em 0 0;
    border-bottom: none;
  }
}

.quote {
  display: block;
  margin: 0;
  padding: 0;

  border-top: 1px solid $color-white;
  border-bottom: 1px solid $color-white;
  background-color: $color-red-light;
  @include graphic-bkgd;

  text-align: center;
  color: $color-white;
  font-family: $accent-font;
  font-size: 2.2em;
  font-weight: bold;
  line-height: 1.2;

  &.quote-bkgd {
    background-repeat: no-repeat;
    background-size: cover;

    .quote-mask {
      background: rgba($color-grey-dark, .33);
      padding: 2em 0;
    }
  }
  .container {
    padding: 2.9em 0;
    max-width: 900px;
  }
  .quote-text {
    &:after {
      display: block;
      content: '';

      height: 4px;
      width: 60px;
      margin: 20px auto 10px;
      background: $color-white;
    }
  }

  p {
    padding: 0 0 .75em;

    &:last-child {
      padding: 0;
    }
  }
}

.video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	margin: 1em 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.form,
.embed {
  padding: 3em 0;
}
