.article-info {
  padding: 4em 0;
}

.article-detail {
  .main-content {
    @media all and (max-width: $medium-screen) {
      padding: 2em 0;
      .share { display: none; }      
    }
  }
}

.author {
  border-bottom: 1px solid $hr-color;
  padding-bottom: 2em;
  margin-bottom: 1em;

  font-size: .85em;

  img {
    margin: 0 auto 1em;
    border-radius: 50%;
  }
  p {
    margin: 0;
    padding: 0;
  }
  strong {
    font-size: 1.25em;
  }

  @media all and (max-width: $medium-screen) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      flex: 0 1 25%;
      max-width: 25%;
    }
    .author-text {
      flex: 0 1 70%;
    }
  }
}