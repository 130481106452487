.special-feature {
  background-color: $color-red-light;
  @include graphic-bkgd;
  padding: 3em 0;

  text-align: center;
  color: $color-white;
  font-size: 1.3em;
  line-height: 1.25;

  .container {
    max-width: $home-width;
    text-align: left;

    .special-text {
      margin-bottom: 2em;
    }

    @media screen and (min-width: $small-screen) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .special-text {
        flex: 1;
        margin: 0;
      }
      .special-link {
        flex: 0 1 25%;
        margin-left: 2em;
      }
    }
  }

  h2 {
    margin: 0;
    color: inherit;
  }
}
.news{
  background: $color-offwhite;
  padding: 2em 0;
  position: relative;

  &:after {
    display: block;
    content: '';

    position: absolute;
    bottom: 0;
    z-index: 0;

    width: 100%;
    height: 150px;

    background-color: $color-white;

    pointer-events: none;
  }
  .container {
    max-width: $home-width;
    position: relative;
    z-index: 1;
  }
}

.statistics {
  background-repeat: no-repeat;
  background-size: cover;

  color: $color-white;
  font-size: 1em;
  line-height: 1.6;

  .container {
    width: 100%;
    max-width: $large-screen;
  }

  .stat-mask {
    background: rgba(0, 0, 0, 0.2);;

    text-align: center;
  }
  .stat-list {
    @media all and (min-width: $small-screen) {
      display: flex;
    }
  }
  .stat {
    flex: 1;
    border-top: 1px solid rgba($color-white, .2);
    padding: 3em 2em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:first-child {
      border: 0px !important;
    }
    @media all and (min-width: $small-screen) {
      border-top: 0px;
      border-left: 1px solid rgba($color-white, .2);
      padding: 6em 2em;
    }
  }
  h2 {
    margin: 0 0 20px;

    color: inherit;
    font-size: 3em;
    font-weight: 900;
    line-height: 1;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.25);

    @media all and (min-width: $small-screen) {
      font-size: 4.1em;
    }
  }
  .button a {
    @media all and (min-width: $small-screen) {
      font-size: .95em;
    }
  }

}
